<template>
  <div class="home pa-2">

    <page-card
        cardTitle="Welcome!"
        cardTitleIcon="mdi-heart-multiple-outline"
        cardSubtitle="You are in the CherryTop Weddings Booklet Builder."
    >
    <v-card-text>
    <v-card elevation="2" class="pa-2">
      <p>This is where you will create your Love Story experience for your special day.</p>
      <p>Personalising and building your booklets is very simple, and we have plenty of instructions and help available all the way through.</p>
      <p>Don’t worry, you won’t need to build your booklet all in one go. You can log out and in as many times as you like and each time you will find your Love Story safe and secure and as it was when you left it.</p>
      <p>The booklets are built by following our step by step process. You can see your progress as you complete each step in the menu bar on the left.</p>
      <p>Once you have completed all of the steps, you will be ready to submit your booklets to us for printing. After that point, the booklet will be locked and no further changes can be made.</p>
      <p>We hope you enjoy building your perfect booklets. Choosing your photos and creating the activities should take you on a proverbial trip down memory lane, so take your time, and know that your efforts here will be rewarded when your guests experience your Love Story for themselves on your special day.</p>
      <p>Should you get stuck at any point or have any questions, we have created a Help & FAQ section which will guide you through the entire process.</p>
      <p>When you are ready, press next to head to the Getting Started section, and enjoy!</p>
    </v-card>
    </v-card-text>

    </page-card>
  </div>
</template>

<script>
import PageCard from "@/components/PageCard";

export default {
  name: 'Home',
  components: {
    PageCard
  },
  computed: {
    queryAbsentFriends() {
      return this.$route.query.af || false
    },
    queryCustomCovers() {
      return this.$route.query.cc || false
    },
  },
  watch: {
    queryAbsentFriends () {
      if (this.queryAbsentFriends === "true") {
        console.log("Absent friends now enabled.")
        this.$store.commit("setAbsentFriends", true )
      } else {
        this.$store.commit("setAbsentFriends", false )
      }
    },
    queryCustomCovers () {
      if (this.queryCustomCovers === "true") {
        console.log("Custom covers now enabled.")
        this.$store.commit("setCustomCovers", true )
      } else {
        this.$store.commit("setCustomCovers", false )
      }
    }
  }
}
</script>
